import React from 'react'
import {useIntl} from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import style from '../assets/css/infoPage.module.css';
import Hero from "../components/heroBanner/heroBanner";

const PrivacyPage = (props) => {
    const intl = useIntl()

    return (
        <Layout>
            <Seo
                title={"Privacy Policy"}
                lang={intl.formatMessage({id: 'MAIN_LANGUAGE'})}
                url={props.location.pathname}
                description={"Privacy Policy samsaidyes.com/sam.event by SAM Entertainment"}
            />

            <Hero title="Privacy Policy"/>
            <section className={`${style.infoSection}`}>

                <article>
                    <p className={style.marginS}>We respect your privacy. Your photos and videos belong to you. We do,
                        of course, not sell your personal data.
                        On the basis of these principles, we process your personal data. More details below.</p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>1. Who is responsible for your personal data?</h2>
                <article>
                    <p className={style.marginS}>In this Privacy Policy, we describe how we, SAM Entertainment AG
                        ("SAM", "we",
                        "us", "our"), process your personal data when:</p>
                    <ul className={`${style.privacyList} ${style.marginXS}`}>
                        <li>
                            <p>you visit our website at www.samsaidyes.com, www.sam.events or any other website provided
                                by us (incl. online shops, mini sites and social media presences, etc.) (collectively,
                                the "Website");</p>
                        </li>
                        <li>
                            <p>you use our software/technology platform (available as app and web version, the
                                "Software") or any other services offered through our Website (collectively, the
                                "Services");</p>
                        </li>
                        <li>
                            <p>you apply for a job with us; or</p>
                        </li>
                        <li>
                            <p>we receive your personal data on other occasions within the scope of our business
                                activities.</p>
                        </li>
                    </ul>
                    <p className={style.marginS}>We process your personal data either:</p>
                    <ul className={`${style.privacyList} ${style.marginXS}`}>
                        <li>
                            <p>as a data controller, which means that we are responsible to you for your personal data;
                                or</p>
                        </li>
                        <li>
                            <p>as a processor/service provider in performance of a contract with your company or other
                                institution. In this case, your company or other institution is primarily responsible to
                                you for your personal data.</p>
                        </li>
                    </ul>

                    <h3 className={`${style.marginS}`}>Contact details</h3>
                    <p className={style.marginS}>
                        SAM Entertainment AG <br/>
                        Hofstrasse 124 <br/>
                        8044 Zurich <br/>
                        Switzerland <br/>
                        e-mail: hello@samsaidyes.com <br/>
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>2. Applicable law</h2>
                <article>
                    <p className={style.marginS}>
                        This Privacy Policy is based on the requirements of the Swiss Federal Act on Data Protection
                        (FADP) and the EU General Data Protection Regulation (GDPR).
                        For special requirements under US law, in particular regarding children under the age of 13, see
                        the additional paragraphs at the end of this Privacy Policy (sections 12 f.).Which legislation
                        applies in each individual case depends on the specific situation.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>3. What personal data do we process?</h2>
                <article>
                    <h3 className={style.marginS}>
                        3.1 When you visit our Website
                    </h3>
                    <p className={style.marginXS}>
                        When you visit our Website, the server automatically logs general technical information. This
                        data includes your IP address, operating system, browser type, information about the device you
                        use to access the Website, referring pages, etc.
                        If you contact us through the Website (e.g., through a contact form or any other means of
                        communication made available through the website), book a software demo, sign up for our
                        newsletter or download content from our Website, we process your contact details and other
                        personal data you provide us with, as well as, if applicable, technical data that may accrue
                        during the use of the respective means of communication.
                        We use the following cookies and other tracking technologies (for the sake of simplicity,
                        collectively "Cookies"):
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li>
                            <p>essential and functional Cookies to ensure the functionality and security of our website,
                                and to make it easier for you to visit our website;</p>
                        </li>
                        <li>
                            <p>statistics Cookies to evaluate the use of our website and to collect information to
                                improve our products and services; </p>
                        </li>
                        <li>
                            <p>marketing Cookies.</p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        You can find more information about the specific Cookies we use and enable/disable them via our
                        Cookie Banner.
                        We also use social media plugins and embedded media from external platforms such as Vimeo, for
                        example to offer you multimedia content. When you access a page on our Website where a plugin or
                        embedded media is present, certain technical data is automatically transmitted to the provider
                        of these services. If you have an account with the relevant provider and are logged in, this
                        interaction may also be captured. Please consult the privacy policies of the respective
                        providers for more information about their data collection and processing.
                    </p>
                    <h3 className={style.marginS}>
                        3.2 When you use our Services
                    </h3>
                    <p className={style.marginXS}>
                        When you use our Services, we process your contact details and other relevant personal data you
                        provide on these occasions. Depending on how you use our Services, we process different personal
                        data:
                    </p>
                    <h4 className={style.marginXS}>As a host (creates event/album) </h4>
                    <ul className={`${style.marginXS}`}>
                        <li>
                            <p>Login/profile data: When you register to use our Services, we collect your email address
                                and
                                other relevant personal data you provide to us on that occasion. If you authenticate
                                through
                                an existing account (Facebook, Google, or similar), we receive the necessary personal
                                data
                                (e.g., email address) from these services. </p>
                        </li>
                        <li><p>
                            Communication data: We store communications between you and us, such as service-related
                            emails regarding account management, and communications regarding technical issues or
                            changes to the Services.</p>
                        </li>
                        <li><p>
                            Technical and usage data: Our servers log general technical data. For more information on
                            this and on our use of Cookies see section 3.1 above. We also process technical data in
                            connection with your use of the Services (in particular, usage data).</p>
                        </li>
                        <li><p>
                            Photos and videos: We process the photos and videos you upload. However, we do not acquire
                            ownership of them nor sell them to any third party.</p>
                        </li>
                        <li><p>
                            Other personal data: We process any other personal data you transmit to us while using the
                            Services or that results from your use of the Services, like payment references or
                            communication between you and members of an event/album.</p>
                        </li>
                    </ul>
                    <h4 className={style.marginXS}>As a moderator or as a guest (invited to join event/album)</h4>
                    <ul className={`${style.marginXS}`}>
                        <li><p>
                            Login/profile data (moderators and guests with account only): We collect the personal data
                            you provide when you create your login to use our Services, which may include your username,
                            name, email, picture, and preferred language. If you authenticate through an existing
                            account (Facebook, Google, or similar), we receive the necessary personal data from these
                            services.</p>
                        </li>
                        <li><p>
                            Technical and usage data (all): Our servers log general technical data. For more information
                            on this and on our use of Cookies see section 3.1 above. We also process technical data in
                            connection with your use of the Services (in particular, usage data).</p>
                        </li>
                        <li><p>
                            Photos and videos (all): We process the photos and videos you upload. However, we do not
                            acquire ownership of them nor sell them to any third party.</p>
                        </li>
                        <li><p>
                            Other personal data (all): We process any other personal data you transmit to us while using
                            the Services or that results from your use of the Services, like payment references or
                            communication between you and members of an event/album.</p>
                        </li>
                    </ul>
                    <h3 className={style.marginS}>
                        3.3 When you apply for a position with us
                    </h3>
                    <p className={style.marginXS}>
                        When you apply for a position with us, we collect and process the necessary personal data to
                        review your application and conduct the application process. This includes, in particular:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li><p>
                            contact details (e.g., name, email address, address, phone number, etc.);</p>
                        </li>
                        <li><p>
                            information about your professional background and qualifications;</p>
                        </li>
                        <li><p>
                            other data from or necessary for the review of your application.</p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        You submit most of this data directly to us as part of your application. In addition, we process
                        data from other sources, in particular from references (if you have consented to references
                        being obtained), as well as from publicly accessible sources (e.g., professional social
                        networks, the internet).
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>4. For what purposes and on what basis do we process your personal
                    data? </h2>
                <article>
                    <h3 className={style.marginS}>
                        4.1 When you use our Services
                    </h3>
                    <p className={style.marginXS}>
                        Our primary purpose of processing your personal data is to provide you with our Services
                        (especially our Software). Accordingly, our grounds for processing is the contract for the use
                        of our Services:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li>
                            <p>with you, if we act as a data controller; or </p>
                        </li>
                        <li>
                            <p>with your company or other institution if we act as a data processor. </p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        Furthermore, we may process your personal data:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li><p>
                            if we need to do so in order to comply with legal obligations;</p>
                        </li>
                        <li><p>
                            based on and within the scope of your consent, if applicable. You can revoke a previously
                            given consent at any time;</p>
                        </li>
                        <li><p>
                            where legally permitted, based on our interests to communicate with you and third parties
                            (even outside the preparation or conclusion of a contract), to ensure IT security and data
                            protection, to provide and improve the Website and the Services and track their use, to
                            optimize your user experience, to maintain and potentially expand our business relationship
                            with you, to improve, expand, and market our offerings and offerings of third parties, to
                            prevent crimes and other misconduct (e.g., content moderation), and to enforce, defend, or
                            avert legal claims.</p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        As part of providing you with our Services or based on our interest in informing people who are
                        interested in our Services about our offers and any new developments, we may send you a
                        newsletter as well as specific mailings with information to support you in using our Services.
                        These emails may contain visible or invisible images. When you download these images from the
                        server, we can see whether and when you have opened the email. This allows us to better
                        understand how you use our offerings and customize them for you. You can turn off this feature
                        in your email program. You also have the option to opt out of receiving our newsletter or
                        mailings at any time.
                    </p>
                    <h3 className={style.marginS}>
                        4.2 In other contexts
                    </h3>
                    <p className={style.marginXS}>
                        We process your personal data in other contexts:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li><p>
                            to prepare, conclude, fulfill, and enforce a contract with you within the scope of our
                            business activities;</p>
                        </li>
                        <li><p>
                            to comply with legal obligations (e.g., keeping business records); or</p>
                        </li>
                        <li><p>
                            based on and within the scope of your consent, if applicable. You can revoke a previously
                            given consent at any time.</p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        In addition, we process your personal data, to the extent permitted by law, in line with our
                        legitimate interests to communicate with you and third parties (even outside the preparation or
                        conclusion of a contract), to provide and improve the Website and the Services and track their
                        use, to optimize your user experience, to maintain and potentially expand our business
                        relationship with you, to improve, expand, and market our offerings, to ensure IT security and
                        data protection, and to enforce, defend, or avert legal claims.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>5.When and how do we transfer your personal data to third parties? </h2>
                <article>
                    <p className={style.marginXS}>
                        To fulfil our contract, to protect our interests or to comply with legal regulations, it may be
                        necessary for us to transfer your personal data to third parties. This includes, for example:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li><p>
                            our IT service providers and other third-party providers whose services we use for the
                            purposes described in this Privacy Policy;</p>
                        </li>
                        <li><p>
                            cases where disclosure is necessary to (i) comply with legal obligations, (ii) ensure IT
                            security and data protection, or (iii) enforce, defend, or avert legal claims;</p>
                        </li>
                        <li><p>
                            third parties to whom we transfer our company or parts thereof, or with whom we merge.</p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        In this context, we also transfer personal data abroad. We limit the transfer of personal data
                        outside Switzerland, the EU, and the EEA as far as possible, but it cannot be entirely avoided.
                        If the respective recipient country does not have a level of data protection recognized by
                        Switzerland, we use standard contractual clauses to ensure adequate data protection, where
                        necessary and possible supplemented by additional security measures.
                        We do not rent or sell your personal data to any third parties.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>6. Data security </h2>
                <article>
                    <p className={style.marginXS}>
                        We protect your personal data by means of adequate technical and organizational security
                        measures against unintentional, illegal or unauthorized manipulation, deletion, modification,
                        access, disclosure, use or loss. This includes, in particular, the following measures:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li><p>our database is stored on secure servers;</p>
                        </li>
                        <li><p>
                            only select employees have access to your data;</p>
                        </li>
                        <li><p>
                            data is encrypted at rest;</p>
                        </li>
                        <li><p>
                            we use/respect the OWASP Top 10;</p>
                        </li>
                        <li><p>
                            we use OpenID Connect (OIDC);</p>
                        </li>
                        <li><p>
                            we do regular backups (clarification: no backups of user content).</p>
                        </li>
                    </ul>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>7. How long do we keep your personal data? </h2>
                <article>
                    <p className={style.marginXS}>
                        We only store your personal data for as long and to the extent necessary for the purposes
                        described above or for legal reasons.
                        Users can delete their own user content (in particular, photos and videos) at any time. Hosts
                        and moderators can also delete user content of other users in the events/albums they host or
                        moderate. Please be aware that deleted user content cannot be recovered.
                        You can contact us at any time if you wish to exercise your right to have your personal data
                        deleted (see section 8 below).
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>8. What are your rights in connection with your personal data? </h2>
                <article>
                    <p className={style.marginXS}>
                        Subject to the conditions of the applicable data protection laws, you have the following rights
                        in connection with your personal data:
                    </p>
                    <ul className={`${style.marginXS}`}>
                        <li>
                            <p>right to access your personal data; </p>
                        </li>
                        <li>
                            <p>right to have inaccurate personal data rectified; </p>
                        </li>
                        <li>
                            <p>right to erasure ("right to be forgotten"); </p>
                        </li>
                        <li>
                            <p>right to restrict the processing of your personal data; </p>
                        </li>
                        <li>
                            <p>right to data portability; </p>
                        </li>
                        <li>
                            <p>right to object to the processing of your personal data. </p>
                        </li>
                    </ul>
                    <p className={style.marginXS}>
                        Please note that exceptions apply to these rights. In particular, we may be obliged to further
                        process your personal data in order to fulfil a contract, to protect our own legitimate
                        interests, such as the assertion, exercise or defense of legal claims, or to comply with legal
                        obligations. In these cases, we can or must reject certain requests or comply with them only to
                        a limited extent.
                    </p>
                    <p className={style.marginXS}>
                        If we act as a data processor, we will forward your request to the relevant data controller.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>9. Links to other websites </h2>
                <article>
                    <p className={style.marginXS}>
                        Our Website may link to third-party websites that are not operated or controlled by us. We are
                        not responsible for whether and how these third parties comply with data protection laws.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>10. Complaints </h2>
                <article>
                    <p className={style.marginXS}>
                        If you are not satisfied with the way in which we process your personal data, you have the right
                        to complain to the <a href={"https://www.edoeb.admin.ch/"}>Swiss Federal Data Protection and
                        Information Commissioner FDPIC</a> or, if the GDPR is applicable, to your competent supervisory
                        authority.
                        Please contact us first before submitting a complaint. This way, we can try to resolve it
                        directly. The easiest way is to contact us by email at <a
                        href={"mailto:hello@samsaidyes.com"}>hello@samsaidyes.com</a>.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>11. Modifications </h2>
                <article>
                    <p className={style.marginXS}>
                        We may modify this Privacy Policy at any time. New versions will come into effect for you as
                        soon as we have notified you by publishing them on our Website.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <h2 className={style.marginS}>12. Special Provisions for the USA </h2>
                <article>
                    <h3 className={style.marginS}>
                        12.1 Children's Data
                    </h3>
                    <p className={style.marginXS}>
                        Our Services are not directed to minors under the age of 13. If you are under the age of 13, the
                        use of the Services is prohibited.
                        If we learn that we have collected personal data from a minor of the age under 13, we will
                        delete such data as quickly as possible. Should anyone believe that we might have any personal
                        data from a minor under the age of 13, please contact us via <a
                        href={"mailto:hello@samsaidyes.com"}>hello@samsaidyes.com</a>.
                    </p>

                    <h3 className={style.marginS}>
                        12.2 How do we respond to Do Not Track signals?
                    </h3>
                    <p className={style.marginXS}>
                        We do not comply with Do Not Track signals or other similar signals as it is not an aspect of
                        the functionality of our Website or Services.
                    </p>
                </article>
            </section>

            <section className={`${style.infoSection}`}>
                <p className={style.marginS}>Last updated on January 10, 2024.</p>
            </section>

        </Layout>
    )
}

export default PrivacyPage
